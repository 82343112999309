import React from 'react';

const PageHeader = ({ title, subtitle }) => (
  <header className="section">
    <h1 className="title">{title}</h1>
    {subtitle && <p className="subtitle">{subtitle}</p>}
  </header>
);

export default PageHeader;
