import React, { useState, useEffect, Suspense } from 'react';
import { useLocation, Routes, Route, matchPath } from 'react-router-dom';
import { Trans } from '@lingui/react/macro';
import { ToastContainer, toast } from 'react-toastify';
import queryString from 'query-string';
import classnames from 'classnames';

import routes from 'routes';
import { useStateValue } from 'state/State';
import { catalogs } from 'utils/catalogs';
import Header from 'partials/Header';
import Notifications from 'partials/Notifications';
import Message from 'partials/Message';
import Blocked from 'components/Blocked';
import Logger from 'components/Logger';
import Error from 'components/Error';
import Loading from 'components/Loading';

const AppBody = () => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [
    {
      user,
      settings: { language },
    },
  ] = useStateValue();

  const [online, setOnline] = useState(navigator.onLine);

  window.addEventListener('load', function () {
    window.addEventListener('online', () => {
      setOnline(navigator.onLine);
    });
    window.addEventListener('offline', () => {
      setOnline(navigator.onLine);
    });
  });

  useEffect(() => {
    if (!online) {
      toast.error(<Trans>Perdeste a ligação à internet.</Trans>);
    }
  }, [online]);

  useEffect(() => {
    const route = routes.find((route) => matchPath(route, location.pathname));
    const title =
      location.pathname === '/' || !route
        ? process.env.REACT_APP_NAME
        : `${process.env.REACT_APP_NAME} | ${
            catalogs[language].messages[route.title.props.id]
          }`;
    document.title = title;
  }, [location, language]);

  const standalone = queryParams.standalone;

  return (
    <>
      {!standalone && <Header />}
      <Notifications />
      <main className={classnames('section', { standalone: standalone })}>
        <div className="container">
          {online ? (
            <Suspense fallback={<Loading />}>
              <Routes>
                {routes.map((route, index) => {
                  let Element = route.element;
                  if (user && user.valid_roles.blocked && !route.free) {
                    Element = Blocked;
                  }
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      element={<Element />}
                    />
                  );
                })}
              </Routes>
            </Suspense>
          ) : (
            <Error status={666} />
          )}
        </div>
      </main>
      {online && <Message />}
      <ToastContainer />
      {user && online && <Logger />}
    </>
  );
};

export default AppBody;
