import React from 'react';

import * as classes from './Loading.module.scss';

const Loading = ({ type = 'block' }) => (
  <div className={classes[type]}>
    <i className="fa-spin fa-solid fa-circle-notch" />
  </div>
);

export default Loading;
