import React, { lazy } from 'react';
import { Trans } from '@lingui/react/macro';

const Home = lazy(() => import('views/Home'));
const Login = lazy(() => import('views/Login'));
const RecoverPassword = lazy(() => import('views/RecoverPassword'));
const ResetPassword = lazy(() => import('views/ResetPassword'));
const Register = lazy(() => import('views/Register'));
const Logout = lazy(() => import('views/Logout'));
const Account = lazy(() => import('views/Account'));
const Profile = lazy(() => import('views/Profile'));
const StatisticsComparison = lazy(() => import('views/StatisticsComparison'));
const NationalRanking = lazy(() => import('views/NationalRanking'));
const QuizNations = lazy(() => import('views/QuizNations/QuizNations'));
const Ranking = lazy(() => import('views/Ranking'));
const Cup = lazy(() => import('views/Cup'));
const Seasons = lazy(() => import('views/Seasons'));
const Quizzes = lazy(() => import('views/Quizzes'));
const SpecialQuizzes = lazy(() => import('views/SpecialQuizzes'));
const Game = lazy(() => import('views/Game'));
const CupGame = lazy(() => import('views/CupGame'));
const Question = lazy(() => import('views/Question'));
const Quiz = lazy(() => import('views/Quiz'));
const SpecialQuiz = lazy(() => import('views/SpecialQuiz'));
const Rules = lazy(() => import('views/Rules'));
const Invitations = lazy(() => import('views/Invitations'));
const GenreRanking = lazy(() => import('views/GenreRanking'));
const SpecialQuizProposal = lazy(() => import('views/SpecialQuizProposal'));
const LeagueQuestions = lazy(() => import('views/LeagueQuestions'));

const NationalRankingAdminRankings = lazy(() =>
  import('views/admin/nationalRanking/Rankings')
);
const NationalRankingAdminEventsList = lazy(() =>
  import('views/admin/nationalRanking/Events/List')
);
const NationalRankingAdminEventsForm = lazy(() =>
  import('views/admin/nationalRanking/Events/Form')
);
const NationalRankingAdminPlayersList = lazy(() =>
  import('views/admin/nationalRanking/Players/List')
);
const NationalRankingAdminPlayersForm = lazy(() =>
  import('views/admin/nationalRanking/Players/Form')
);
const NotificationsAdmin = lazy(() =>
  import('views/admin/notifications/Notifications')
);
const UsersAdmin = lazy(() => import('views/admin/users/Users'));
const QuizzesAdmin = lazy(() => import('views/admin/quizzes/Quizzes'));
const QuizForm = lazy(() => import('views/admin/quizzes/QuizForm'));
const QuizCorrect = lazy(() => import('views/admin/quizzes/QuizCorrect'));
const SpecialQuizzesAdmin = lazy(() =>
  import('views/admin/specialQuizzes/SpecialQuizzes')
);
const SpecialQuizForm = lazy(() =>
  import('views/admin/specialQuizzes/SpecialQuizForm')
);
const SpecialQuizProposalEdit = lazy(() =>
  import('views/admin/specialQuizzes/SpecialQuizProposalEdit')
);
const SpecialQuizCorrect = lazy(() =>
  import('views/admin/specialQuizzes/SpecialQuizCorrect')
);
const SeasonsAdmin = lazy(() => import('views/admin/seasons/Seasons'));
const SeasonForm = lazy(() => import('views/admin/seasons/SeasonForm'));
const Questions = lazy(() => import('views/admin/questions/Questions'));
const ExternalQuestions = lazy(() =>
  import('views/admin/externalQuestions/ExternalQuestions')
);

const NoMatch = lazy(() => import('components/NoMatch'));

const routes = [
  {
    title: process.env.REACT_APP_NAME,
    path: '/',
    element: Home,
  },
  {
    title: <Trans>Entrar</Trans>,
    path: '/login',
    element: Login,
  },
  {
    title: <Trans>Recuperar palavra-passe</Trans>,
    path: '/recover-password',
    element: RecoverPassword,
  },
  {
    title: <Trans>Redefinir palavra-passe</Trans>,
    path: '/reset-password/:token',
    element: ResetPassword,
  },
  {
    title: <Trans>Registo</Trans>,
    path: '/register',
    element: Register,
  },
  {
    title: <Trans>Sair</Trans>,
    path: '/logout',
    free: true,
    element: Logout,
  },
  {
    title: <Trans>Conta</Trans>,
    path: '/account',
    free: true,
    element: Account,
  },
  {
    title: <Trans>Regras</Trans>,
    path: '/rules',
    element: Rules,
  },
  {
    title: <Trans>Convites</Trans>,
    path: '/invitations',
    element: Invitations,
  },
  {
    title: <Trans>Rankings temáticos</Trans>,
    path: '/genre-rankings/:season?/:genre?',
    element: GenreRanking,
  },
  {
    title: <Trans>Perfil</Trans>,
    path: '/profile/:id?/:tab?',
    element: Profile,
  },
  {
    title: <Trans>Comparação de Estatísticas</Trans>,
    path: '/statistics-comparison/:id1/:id2',
    element: StatisticsComparison,
  },
  {
    title: <Trans>Ranking Nacional</Trans>,
    path: '/national-ranking/:month?',
    free: true,
    element: NationalRanking,
  },
  {
    title: <Trans>Quiz Nations</Trans>,
    path: '/quiz-nations/:token?',
    free: true,
    element: QuizNations,
  },
  {
    title: <Trans>Classificação</Trans>,
    path: '/ranking/:season?/:tier?',
    element: Ranking,
  },
  {
    title: <Trans>Lista de perguntas</Trans>,
    path: '/questions/:season',
    element: LeagueQuestions,
  },
  {
    title: <Trans>Taça</Trans>,
    path: '/cup/:season?',
    element: Cup,
  },
  {
    title: <Trans>Arquivo de temporadas</Trans>,
    path: '/seasons/:page?',
    element: Seasons,
  },
  {
    title: <Trans>Arquivo de quizzes</Trans>,
    path: '/quizzes/:page?',
    element: Quizzes,
  },
  {
    title: <Trans>Arquivo de quizzes especiais</Trans>,
    path: '/special-quizzes/:page?',
    element: SpecialQuizzes,
  },
  {
    title: <Trans>Jogo da Liga</Trans>,
    path: '/game/:date/:user1/:user2?',
    element: Game,
  },
  {
    title: <Trans>Jogo da Taça</Trans>,
    path: '/cup-game/:date/:user1/:user2',
    element: CupGame,
  },
  {
    title: <Trans>Pergunta</Trans>,
    path: '/question/:id',
    element: Question,
  },
  {
    title: <Trans>Quiz</Trans>,
    path: '/quiz/:date?',
    element: Quiz,
  },
  {
    title: <Trans>Quiz Especial</Trans>,
    path: '/special-quiz/:date?',
    element: SpecialQuiz,
  },
  {
    title: <Trans>Propor quiz especial</Trans>,
    path: '/create-special-quiz',
    element: SpecialQuizProposal,
  },
  {
    title: <Trans>Ranking Nacional | Ranking</Trans>,
    path: '/admin/national-ranking/ranking',
    element: NationalRankingAdminRankings,
  },
  {
    title: <Trans>Ranking Nacional | Provas mensais</Trans>,
    path: '/admin/national-ranking/events',
    element: NationalRankingAdminEventsList,
  },
  {
    title: <Trans>Ranking Nacional | Provas mensais | Criar</Trans>,
    path: '/admin/national-ranking/events/create',
    element: NationalRankingAdminEventsForm,
  },
  {
    title: <Trans>Ranking Nacional | Provas mensais | Editar</Trans>,
    path: '/admin/national-ranking/events/:month/edit',
    element: NationalRankingAdminEventsForm,
  },
  {
    title: <Trans>Ranking Nacional | Jogadores</Trans>,
    path: '/admin/national-ranking/players',
    element: NationalRankingAdminPlayersList,
  },
  {
    title: <Trans>Ranking Nacional | Jogadores | Criar</Trans>,
    path: '/admin/national-ranking/players/create',
    element: NationalRankingAdminPlayersForm,
  },
  {
    title: <Trans>Ranking Nacional | Jogadores | Editar</Trans>,
    path: '/admin/national-ranking/players/:id/edit',
    element: NationalRankingAdminPlayersForm,
  },
  {
    title: <Trans>Notificações</Trans>,
    path: '/admin/notifications',
    element: NotificationsAdmin,
  },
  {
    title: <Trans>Utilizadores</Trans>,
    path: '/admin/users',
    element: UsersAdmin,
  },
  {
    title: <Trans>Quizzes</Trans>,
    path: '/admin/quizzes/:page?',
    element: QuizzesAdmin,
  },
  {
    title: <Trans>Quiz | Criar</Trans>,
    path: '/admin/quiz/create',
    element: QuizForm,
  },
  {
    title: <Trans>Quiz | Corrigir</Trans>,
    path: '/admin/quiz/:date/correct',
    element: QuizCorrect,
  },
  {
    title: <Trans>Quiz | Editar</Trans>,
    path: '/admin/quiz/:date/edit',
    element: QuizForm,
  },
  {
    title: <Trans>Quizzes especiais</Trans>,
    path: '/admin/special-quizzes/:page?',
    element: SpecialQuizzesAdmin,
  },
  {
    title: <Trans>Quiz especial | Criar</Trans>,
    path: '/admin/special-quiz/create',
    element: SpecialQuizForm,
  },
  {
    title: <Trans>Quiz especial | Corrigir</Trans>,
    path: '/admin/special-quiz/:date/correct',
    element: SpecialQuizCorrect,
  },
  {
    title: <Trans>Quiz especial | Editar</Trans>,
    path: '/admin/special-quiz/:date/edit',
    element: SpecialQuizForm,
  },
  {
    title: <Trans>Proposta de quiz especial | Editar</Trans>,
    path: '/admin/special-quiz-proposal/:id/edit',
    element: SpecialQuizProposalEdit,
  },
  {
    title: <Trans>Temporadas</Trans>,
    path: '/admin/seasons/:page?',
    element: SeasonsAdmin,
  },
  {
    title: <Trans>Temporada | Criar</Trans>,
    path: '/admin/season/create',
    element: SeasonForm,
  },
  {
    title: <Trans>Temporada | Editar</Trans>,
    path: '/admin/season/:season/edit',
    element: SeasonForm,
  },
  {
    title: <Trans>Pesquisa de perguntas</Trans>,
    path: '/admin/questions',
    element: Questions,
  },
  {
    title: <Trans>Pesquisa de perguntas externas</Trans>,
    path: '/admin/external-questions',
    element: ExternalQuestions,
  },
  {
    title: <Trans>Página não encontrada</Trans>,
    element: NoMatch,
  },
];

export default routes;
