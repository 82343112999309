import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Trans } from '@lingui/react/macro';
import classames from 'classnames';
import Cookies from 'js-cookie';

import { useStateValue } from 'state/State';

const UserDropdownMenu = () => {
  const [{ user }] = useStateValue();
  const [menuDropdownOpen, setMenuDropdownOpen] = useState(false);

  const impersonating = Boolean(Cookies.get('impersonating'));

  if (!user) {
    return null;
  }

  return (
    <div
      className={classames('navbar-item', 'has-dropdown', {
        'is-active': menuDropdownOpen,
      })}
    >
      <OutsideClickHandler
        onOutsideClick={() => {
          setMenuDropdownOpen(false);
        }}
        display="flex"
      >
        <button
          className="navbar-link"
          type="button"
          onClick={() => {
            setMenuDropdownOpen(!menuDropdownOpen);
          }}
        >
          <i className="fa-fw fa-solid fa-user" /> {user.name}
        </button>
      </OutsideClickHandler>
      <div className="navbar-dropdown is-right">
        {impersonating && (
          <button
            className="navbar-link is-arrowless is-nowrap"
            onClick={() => {
              const adminToken = Cookies.get('ADMIN-TOKEN');
              Cookies.set('AUTH-TOKEN', adminToken, {
                sameSite: 'strict',
              });
              Cookies.remove('ADMIN-TOKEN');
              Cookies.remove('impersonating');
              document.location.href = '/';
            }}
          >
            <i className="fa-fw fa-solid fa-user-secret" />
            &nbsp;<Trans>Parar de usar identidade</Trans>
          </button>
        )}
        <Link to="/account/" className="navbar-link is-arrowless is-nowrap">
          <i className="fa-fw fa-solid fa-user" />
          &nbsp;<Trans>Conta</Trans>
        </Link>
        {user && (user.valid_roles.admin || user.valid_roles.regular_player) && (
          <>
            <Link to="/invitations" className="navbar-link is-arrowless is-nowrap">
              <i className="fa-fw fa-solid fa-share" />
              &nbsp;<Trans>Convites</Trans>
            </Link>
            <Link to="/seasons" className="navbar-link is-arrowless is-nowrap">
              <i className="fa-fw fa-solid fa-calendar"></i>
              &nbsp;<Trans>Temporadas</Trans>
            </Link>
            <Link to="/quizzes" className="navbar-link is-arrowless is-nowrap">
              <i className="fa-fw fa-solid fa-circle-question" />
              &nbsp;<Trans>Quizzes</Trans>
            </Link>
          </>
        )}
        {user &&
          (user.valid_roles.admin ||
            user.valid_roles.regular_player ||
            user.valid_roles.special_quiz_player) && (
            <Link to="/special-quizzes" className="navbar-link is-arrowless is-nowrap">
              <i className="fa-fw fa-solid fa-circle-question" />
              &nbsp;<Trans>Quizzes especiais</Trans>
            </Link>
          )}
        {user &&
          (user.valid_roles.admin ||
            user.valid_roles.regular_player ||
            user.valid_roles.special_quiz_player) && (
            <Link to="/create-special-quiz" className="navbar-link is-arrowless is-nowrap">
              <i className="fa-fw fa-solid fa-pencil" />
              &nbsp;<Trans>Propor quiz especial</Trans>
            </Link>
          )}
        {user && process.env.REACT_APP_NATIONAL_RANKING === 'true' && (
          <Link to="/national-ranking/" className="navbar-link is-arrowless is-nowrap">
            <i className="fa-fw fa-solid fa-medal" />
            &nbsp;<Trans>Ranking Nacional</Trans>
          </Link>
        )}
        {user && process.env.REACT_APP_QUIZ_NATIONS === 'true' && (
          <Link to="/quiz-nations/" className="navbar-link is-arrowless is-nowrap">
            <i className="fa-fw fa-solid fa-earth-europe" />
            &nbsp;<Trans>Quiz Nations</Trans>
          </Link>
        )}
        <Link to="/logout/" className="navbar-link is-arrowless is-nowrap">
          <i className="fa-fw fa-solid fa-right-from-bracket" />
          &nbsp;<Trans>Sair</Trans>
        </Link>
        <hr className="navbar-divider" />
        <div className="navbar-item">
          <Trans>Versão {process.env.REACT_APP_VERSION}</Trans>
        </div>
      </div>
    </div>
  );
};

export default UserDropdownMenu;
