import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import 'react-datepicker/dist/react-datepicker.css';
import { useStateValue } from 'state/State';
import { catalogs } from 'utils/catalogs';
import Auth from 'utils/Auth';
import 'styles/App.scss';
import AppBody from './AppBody';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowDown,
  faArrowDown19,
  faArrowDown91,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faAt,
  faBan,
  faBell,
  faBomb,
  faBook,
  faBullhorn,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCheck,
  faCircleQuestion,
  faClock,
  faEarthEurope,
  faEnvelope,
  faEye,
  faFaceMeh,
  faFire,
  faGear,
  faGem,
  faGlobe,
  faKey,
  faLinkSlash,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMedal,
  faPencil,
  faPenToSquare,
  faPlus,
  faRightFromBracket,
  faCircleNotch,
  faSort,
  faSquareCheck,
  faStar,
  faThumbsUp,
  faTriangleExclamation,
  faTrophy,
  faShare,
  faShield,
  faTrash,
  faUpload,
  faUser,
  faUserSecret,
  faUsers,
  faXmark,
  faArrowDownShortWide,
  faArrowDownWideShort,
} from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
library.add(
  faArrowDown,
  faArrowDown19,
  faArrowDown91,
  faArrowDownAZ,
  faArrowDownZA,
  faArrowUp,
  faAt,
  faBan,
  faBell,
  faBomb,
  faBook,
  faBullhorn,
  faCalendar,
  faCalendarDay,
  faCalendarDays,
  faCheck,
  faCircleQuestion,
  faClock,
  faEarthEurope,
  faEnvelope,
  faEye,
  faFaceMeh,
  faFire,
  faGear,
  faGem,
  faGlobe,
  faKey,
  faLinkSlash,
  faLock,
  faLockOpen,
  faMagnifyingGlass,
  faMedal,
  faPencil,
  faPenToSquare,
  faPlus,
  faRightFromBracket,
  faCircleNotch,
  faSort,
  faSquareCheck,
  faStar,
  faThumbsUp,
  faTriangleExclamation,
  faTrophy,
  faShare,
  faShield,
  faTrash,
  faUpload,
  faUser,
  faUserSecret,
  faUsers,
  faXmark,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faPaypal
);
dom.watch();

// Lingui
const { messages } = catalogs[process.env.REACT_APP_LANGUAGE];
i18n.load(process.env.REACT_APP_LANGUAGE, messages);
i18n.activate(process.env.REACT_APP_LANGUAGE);

// Date picker
registerLocale('pt', pt);

const App = () => {
  const [
    {
      settings: { language },
    },
  ] = useStateValue();
  setDefaultLocale(language);

  return (
    <Auth>
      <I18nProvider i18n={i18n}>
        <BrowserRouter>
          <AppBody />
        </BrowserRouter>
      </I18nProvider>
    </Auth>
  );
};

export default App;
