import React from 'react';
import { Trans } from '@lingui/react/macro';
import classnames from 'classnames';

import * as classes from './Modal.module.scss';

const Modal = ({
  type,
  size = 'regular',
  title,
  open = false,
  body,
  action = () => {},
  actionDisabled = false,
  doingAction = false,
  onClose = () => {},
  confirmButtonText,
  hideCancel = false,
}) => (
  <article className={classnames('modal', { 'is-active': open })}>
    <div className="modal-background"></div>
    <div className={classnames('modal-card', classes[`size-${size}`])}>
      <header
        className={classnames('modal-card-head', {
          'has-background-info': type === 'info',
          'has-background-danger': type === 'danger',
        })}
      >
        <p
          className={classnames('modal-card-title', {
            'has-background-info': type === 'info',
            'has-background-danger': type === 'danger',
          })}
        >
          {title}
        </p>
        <button
          className={classnames('delete', {
            'is-invisible': doingAction,
          })}
          aria-label="close"
          type="button"
          onClick={onClose}
          disabled={doingAction}
        ></button>
      </header>
      <section className={`modal-card-body ${classes.modalBody}`}>
        {body}
      </section>
      <footer className="modal-card-foot">
        <div className="buttons">
          <button
            type="button"
            className={classnames('button', {
              'is-info': type === 'info',
              'is-danger': type === 'danger',
              'is-loading': doingAction,
            })}
            onClick={action}
            disabled={doingAction || actionDisabled}
          >
            {confirmButtonText ? confirmButtonText : <Trans>OK</Trans>}
          </button>
          {!hideCancel && (
            <button
              type="button"
              className="button"
              onClick={onClose}
              disabled={doingAction}
            >
              <Trans>Cancelar</Trans>
            </button>
          )}
        </div>
      </footer>
    </div>
  </article>
);

export default Modal;
